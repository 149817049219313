import React, { Component } from "react";

import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingScreen from "./components/Loading"
import { BrowserRouter } from "react-router-dom";
import ViewCalculatorScreen from "./screens/ViewCalculatorScreen";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Redirect } from "react-router";
import {
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles/index";
import axios from "axios";

class App extends Component {
  state = {
    appInformation: {
      appType: "",
      primaryColor: null,
      logoUrl: null,
      secondColor: null,
      siteUrl: null
    },
    isLoading: true,
    apiUrl: 'https://api.company-app.info/',
    //apiUrl: 'http://localhost:8000/',
    loadingIcon: '/images/icons/m-solo_2015-256x256.png',
    products: [
        {
            appType: null,
            title: null,
            imageUrl: null,
            descriptionOne: null,
            descriptionTwo: null,
            descriptionThree: null,
            description: null,
            webLink: null,
            densityValue: null,
            jointWidth: null
        }
    ],
    banner: [
        {
            appType: null,
            imageUrl: null,
            altText: null,
            webLink: null,
        }
    ]
  }

  componentDidMount() {
    //Images and Colours
      axios.get( this.state.apiUrl + 'multiflex/_v1/information')
          .then(response => {
              if (response.data.data) {
                  this.setState({appInformation: response.data.data.items[0]})
              }
          })
          .then(() => {
              axios.get(this.state.apiUrl + 'multiflex/_v1/products')
                  .then(response => {
                      if (response.data.data) {
                          this.setState({products: response.data.data.items})
                      }
                  })

              axios.get(this.state.apiUrl + 'multiflex/_v1/banner')
                  .then(response => {
                      if (response.data.data) {
                          this.setState({banner: response.data.data.items})
                      }
                  })
          })
          .then( () => {
              this.setState({isLoading: false})
          })
  }

  render() {
    const {appInformation, isLoading, apiUrl, products, banner, loadingIcon} = this.state;


    const theme = createMuiTheme({
      palette: {
        primary: {
          main: appInformation.primaryColor ? appInformation.primaryColor : "#FFF"
        },
        secondary: {
          main: appInformation.secondColor ? appInformation.secondColor : "#FFF"
        },
        background: {
            default: appInformation.primaryColor ? appInformation.primaryColor : "#FFF"
        }
      }
    });

    return (
      <BrowserRouter>
          {
              isLoading ?
                  <LoadingScreen loadingIcon={loadingIcon}/>
                  :
                  <MuiThemeProvider theme={theme}>
                      <CssBaseline />
                      <Header
                          title="App" logo={appInformation.logoUrl ? apiUrl + appInformation.logoUrl : ""}
                          altText={appInformation.appType ? appInformation.appType + "-logo" : ""}
                          width={200}
                      />
                      <Switch>
                          <Route exact path="/rechner" render={(props) => <ViewCalculatorScreen {...props} products={products} apiUrl={apiUrl} banner={banner}/>}  />
                          <Redirect to="/rechner" />
                      </Switch>
                      <Route render={(props) => <Footer {...props} siteUrl={appInformation.siteUrl} contactMail={appInformation.contactMail} />} />
                  </MuiThemeProvider>
          }

      </BrowserRouter>
    );
  }
}

export default App;
