import React, { Component } from "react";
import "./style.css";

class Input extends Component {
  // state = {
  //     value: ''
  // }

  // onChange = (val) => {
  //     this.setState({value: val})
  // }

  render() {
    const { placeholder, label, value, onChange } = this.props;
    return (
      <div className="app-field">
        <label htmlFor="input" className="app-field__label">
          {label}
        </label>
        <div className="app-field__input-wrapper">
          <input
            placeholder={placeholder}
            type="number"
            name="Länge"
            className="app-field__input-item"
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

export default Input;
