import React, { Component } from "react";
import classNames from "classnames";

import "./style.css";
import SvgIcon from '@material-ui/core/SvgIcon';

class SaveButton extends Component {
  render() {
    const {
      text,
      onClick,
      styleName,
      infoButton,
      onClickInfo,
      isEnabled = true
    } = this.props;

    return (
      <div className={classNames("finish-button", styleName)}>
        <button
          className={classNames("finish-button__item", styleName)}
          onClick={onClick}
          disabled={!isEnabled}
        >
          {text}
        </button>
        {infoButton ? (
          <button
            className={classNames(
              "finish-button__item finish-button__item--small",
              styleName
            )}
            onClick={onClickInfo}
            disabled={!isEnabled}
          >
              <SvgIcon style={{fontSize: 32}} viewBox="0 0 48 48">
                  <circle cy="24" cx="24" r="23" stroke="#000" fill="none"/>
                  <circle cx="24" cy="11.6" r="4.7"/>
                  <path d="m17.4 18.8v2.15h1.13c2.26 0 2.26 1.38 2.26 1.38v15.1s0 1.38-2.26 1.38h-1.13v2.08h14.2v-2.08h-1.13c-2.26 0-2.26-1.38-2.26-1.38v-18.6"/>
              </SvgIcon>
          </button>
        ) : null}
      </div>
    );
  }
}

export default SaveButton;
